import { User } from 'fitify-types/src/types/user'

const ONBOARDING_IMAGES = {
  common: {
    logo: {
      width: 122,
      height: 38,
      srcSet: ['/images/logo.webp', '/images/logo@2x.webp'],
      responsive: {
        width: 90,
        height: 28,
      },
    },
    logoDark: {
      width: 130,
      height: 40,
      srcSet: ['/images/logo-dark.webp', '/images/logo-dark@2x.webp'],
      responsive: {
        width: 104,
        height: 32,
      },
    },
  },
  home: {
    youngCoupleShape: {
      width: 480,
      height: 270,
      srcSet: [
        '/images/onboarding/youngCoupleShapeV2.webp',
        '/images/onboarding/youngCoupleShapeV2@2x.webp',
      ],
    },
    youngCoupleShapeOld: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/youngCoupleShape.webp',
        '/images/onboarding/youngCoupleShape@2x.webp',
      ],
    },
    youngCoupleFullLocalized: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/localized/welcome_full/welcome_full_{{locale}}.webp',
        '/images/onboarding/localized/welcome_full/welcome_full_{{locale}}@2x.webp',
      ],
    },
    youngCoupleCroppedLocalized: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/localized/welcome_cropped/welcome_cropped_{{locale}}.webp',
        '/images/onboarding/localized/welcome_cropped/welcome_cropped_{{locale}}@2x.webp',
      ],
    },
    youngCoupleShapeDark: {
      width: 293,
      height: 300,
      srcSet: [
        '/images/onboarding/youngCoupleShapeDark.webp',
        '/images/onboarding/youngCoupleShapeDark@2x.webp',
      ],
    },
  },
  gender: {
    genderFemale: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/genderFemale.webp',
        '/images/onboarding/genderFemale@2x.webp',
      ],
    },
    genderMale: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/genderMale.webp',
        '/images/onboarding/genderMale@2x.webp',
      ],
    },
  },
  goal: {
    goalFemaleToned: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/goalFemaleToned.webp',
        '/images/onboarding/goalFemaleToned@2x.webp',
      ],
    },
    goalWeight: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/goalWeight.webp',
        '/images/onboarding/goalWeight@2x.webp',
      ],
    },
    goalMaleMuscle: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/goalMaleMuscle.webp',
        '/images/onboarding/goalMaleMuscle@2x.webp',
      ],
    },
    goalFemaleFitter: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/goalFemaleFitter.webp',
        '/images/onboarding/goalFemaleFitter@2x.webp',
      ],
    },
    goalMaleFitter: {
      width: 130,
      height: 94,
      srcSet: [
        '/images/onboarding/goalMaleFitter.webp',
        '/images/onboarding/goalMaleFitter@2x.webp',
      ],
    },
  },
  body: {
    bodyFemaleHourglass: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyFemaleHourglass.webp',
        '/images/onboarding/bodyFemaleHourglass@2x.webp',
      ],
    },
    bodyFemaleRectangle: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyFemaleRectangle.webp',
        '/images/onboarding/bodyFemaleRectangle@2x.webp',
      ],
    },
    bodyFemaleRounded: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyFemaleRounded.webp',
        '/images/onboarding/bodyFemaleRounded@2x.webp',
      ],
    },
    bodyFemaleLightbulb: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyFemaleLightbulb.webp',
        '/images/onboarding/bodyFemaleLightbulb@2x.webp',
      ],
    },
    bodyMaleSkinny: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyMaleSkinny.webp',
        '/images/onboarding/bodyMaleSkinny@2x.webp',
      ],
    },
    bodyMaleIdeal: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyMaleIdeal.webp',
        '/images/onboarding/bodyMaleIdeal@2x.webp',
      ],
    },
    bodyMaleFlabby: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyMaleFlabby.webp',
        '/images/onboarding/bodyMaleFlabby@2x.webp',
      ],
    },
    bodyMaleHeavier: {
      width: 130,
      height: 90,
      srcSet: [
        '/images/onboarding/bodyMaleHeavier.webp',
        '/images/onboarding/bodyMaleHeavier@2x.webp',
      ],
    },
    maleArea: {
      width: 168,
      height: 430,
      srcSet: [
        '/images/onboarding/maleArea.webp',
        '/images/onboarding/maleArea@2x.webp',
      ],
    },
    femaleArea: {
      width: 168,
      height: 430,
      srcSet: [
        '/images/onboarding/femaleArea.webp',
        '/images/onboarding/femaleArea@2x.webp',
      ],
    },
  },
  plan: {
    male: {
      width: 135,
      height: 319,
      srcSet: [
        '/images/onboarding/malePlan.webp',
        '/images/onboarding/malePlan@2x.webp',
      ],
    },
    female: {
      width: 135,
      height: 319,
      srcSet: [
        '/images/onboarding/femalePlan.webp',
        '/images/onboarding/femalePlan@2x.webp',
      ],
    },
  },
  download: {
    phones: {
      width: 389,
      height: 255,
      srcSet: ['/images/download/phones.webp'],
      alt: 'Fitify applications',
    },
  },
  subscription: {
    phones: {
      width: 275,
      height: 419,
      srcSet: [
        '/images/subscription/phones.webp',
        '/images/subscription/phones@2x.webp',
      ],
    },
    paywallDesktopLocalized: {
      width: 316,
      height: 424,
      srcSet: [
        '/images/subscription/localized/paywall_desktop/paywall_desktop_{{locale}}.webp',
        '/images/subscription/localized/paywall_desktop/paywall_desktop_{{locale}}@2x.webp',
      ],
    },
    paywallMobileLocalized: {
      width: 375,
      height: 149,
      srcSet: [
        '/images/subscription/localized/paywall_mobile/paywall_mobile_{{locale}}.webp',
        '/images/subscription/localized/paywall_mobile/paywall_mobile_{{locale}}@2x.webp',
      ],
    },
    paywallMobile: {
      width: 375,
      height: 149,
      srcSet: [
        '/images/subscription/paywall_mobile.webp',
        '/images/subscription/paywall_mobile@2x.webp',
      ],
    },
    specialOffer: {
      width: 170,
      height: 170,
      srcSet: ['/images/subscription/promo_gift.svg'],
    },
    payment_header_shutterstock_jun2021: {
      width: 375,
      height: 240,
      srcSet: [
        '/images/subscription/payment_header_shutterstock_jun2021.webp',
        '/images/subscription/payment_header_shutterstock_jun2021@2x.webp',
      ],
    },
    isic: {
      width: 80,
      height: 80,
      srcSet: ['/images/subscription/isic_logo.svg'],
    },
    itic: {
      width: 80,
      height: 80,
      srcSet: ['/images/subscription/itic_logo.svg'],
    },
  },
  gift: {
    mobileMockup: {
      width: 300,
      height: 245,
      srcSet: [
        '/images/gift/gift-mobile-mockup.webp',
        '/images/gift/gift-mobile-mockup@2x.webp',
      ],
    },
  },
  salesPitch: {
    loseWeightF: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/female-lose-weight.webp',
        '/images/pitch/female-lose-weight@2x.webp',
      ],
    },
    getTonedF: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/female-get-toned.webp',
        '/images/pitch/female-get-toned@2x.webp',
      ],
    },
    loseWeightM: {
      width: 480,
      height: 320,
      srcSet: [
        '/images/pitch/male-lose-weight.webp',
        '/images/pitch/male-lose-weight@2x.webp',
      ],
    },
    buildMuscleM: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/pitch/male-build-muscle.webp',
        '/images/pitch/male-build-muscle@2x.webp',
      ],
    },
    weightChart: {
      width: 480,
      height: 180,
      srcSet: [
        '/images/pitch/weight-curve.webp',
        '/images/pitch/weight-curve@2x.webp',
      ],
    },
    weightChartV2: {
      width: 480,
      height: 195,
      srcSet: [
        '/images/pitch/weight-curve-v2.webp',
        '/images/pitch/weight-curve-v2@2x.webp',
      ],
    },
    weightChartV3: {
      width: 480,
      height: 196,
      srcSet: [
        '/images/pitch/weight-curve-v3.webp',
        '/images/pitch/weight-curve-v3@2x.webp',
      ],
    },
    fitnessChart: {
      width: 480,
      height: 258,
      srcSet: [
        '/images/pitch/fitness-chart.webp',
        '/images/pitch/fitness-chart@2x.webp',
      ],
    },
    kneePain: {
      width: 480,
      height: 278,
      srcSet: [
        '/images/pitch/knee-pitch.webp',
        '/images/pitch/knee-pitch@2x.webp',
      ],
    },
    motivation: {
      width: 364,
      height: 218,
      srcSet: [
        '/images/pitch/pitch-motivation.webp',
        '/images/pitch/pitch-motivation@2x.webp',
      ],
      hasShadow: true,
    },
    motivationV2: {
      width: 280,
      height: 158,
      srcSet: [
        '/images/pitch/pitch-motivation-v2.webp',
        '/images/pitch/pitch-motivation-v2@2x.webp',
      ],
      hasShadow: true,
    },
    motivationV3: {
      width: 316,
      height: 196,
      srcSet: [
        '/images/pitch/pitch-motivation-v3.webp',
        '/images/pitch/pitch-motivation-v3@2x.webp',
      ],
      hasShadow: true,
    },
    commitment: {
      width: 400,
      height: 229,
      srcSet: [
        '/images/pitch/pitch-commitment.webp',
        '/images/pitch/pitch-commitment@2x.webp',
      ],
      hasShadow: true,
    },
    commitmentV2: {
      width: 280,
      height: 158,
      srcSet: [
        '/images/pitch/pitch-commitment-v2.webp',
        '/images/pitch/pitch-commitment-v2@2x.webp',
      ],
      hasShadow: true,
    },
    commitmentV3: {
      width: 332,
      height: 196,
      srcSet: [
        '/images/pitch/pitch-commitment-v3.webp',
        '/images/pitch/pitch-commitment-v3@2x.webp',
      ],
      hasShadow: true,
    },
  },
  leadingImage: {
    improveHealth: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_improve_health_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_improve_health_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
    moreRelaxed: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_more_relaxed_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_more_relaxed_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
    sleepBetter: {
      width: 480,
      height: 273,
      srcSet: [
        '/images/onboarding/leading-images/leading_sleep_better_{{gender}}_{{leadingMediaType}}.webp',
        '/images/onboarding/leading-images/leading_sleep_better_{{gender}}_{{leadingMediaType}}@2x.webp',
      ],
    },
  },
} as const satisfies Record<string, Record<string, OnboardingImage>>

type OnboardingImageKeys = keyof typeof ONBOARDING_IMAGES
type OnboardingImages<Page extends OnboardingImageKeys> =
  keyof (typeof ONBOARDING_IMAGES)[Page]
interface OnboardingImage {
  srcSet: string[]
  width?: number
  height?: number
  hasShadow?: boolean
  responsive?: {
    width?: number
    height?: number
  }
  alt?: string
}

export interface OnboardingImageData {
  src: string
  srcSet: string
  width: number
  height: number
  hasShadow: boolean
  responsive?: {
    width?: number
    height?: number
  }
}

type OnboardingImageMap = {
  [key in OnboardingImageKeys]: {
    [image in OnboardingImages<key>]: OnboardingImage
  }
}
function getImage<T extends OnboardingImageKeys, I extends OnboardingImages<T>>(
  page: T,
  image: I,
  locale?: string
): OnboardingImage {
  const map: OnboardingImageMap = ONBOARDING_IMAGES

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const img = map[page][image]

  if (locale) {
    img.srcSet = img.srcSet.map((src) => src.replace('{{locale}}', locale))
  }

  return img
}

export function getImageProps<
  T extends OnboardingImageKeys,
  I extends OnboardingImages<T>,
>(page: T, image: I, locale?: string): OnboardingImageData {
  const target: OnboardingImage = getImage(page, image, locale)

  return {
    src: target?.srcSet[0] || '',
    srcSet: target?.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',') || '',
    width: target?.width || 0,
    height: target?.height || 0,
    hasShadow: target?.hasShadow || false,
    responsive: {
      width: target.responsive?.width || target.width,
      height: target.responsive?.height || target.height,
    },
  }
}

export async function preloadImages(page: OnboardingImageKeys) {
  const map: {
    [key: string]: { [image: string]: OnboardingImage }
  } = ONBOARDING_IMAGES
  if (!map[page]) return
  const target = Object.values(map[page])
  return Promise.all(
    target.map((item) => {
      return new Promise((resolve, reject) => {
        const img = new Image(item.width, item.height)
        img.src = item.srcSet[0]
        img.srcset = item.srcSet.map((x, i) => `${x} ${i + 1}x`).join(',')

        img.onload = resolve
        img.onerror = reject
      })
    })
  )
}

export const getLeadingImageProps = (
  leadingMediaType: 'photorealistic' | 'illustrated',
  key: keyof OnboardingImageMap['leadingImage'],
  gender: User.Profile.Gender
): OnboardingImageData => {
  const imageProps = getImageProps('leadingImage', key)

  return {
    ...imageProps,
    srcSet: imageProps.srcSet
      .replaceAll('{{gender}}', gender.toString())
      .replaceAll('{{leadingMediaType}}', leadingMediaType),
    src: imageProps.src
      .replaceAll('{{gender}}', gender.toString())
      .replaceAll('{{leadingMediaType}}', leadingMediaType),
  }
}
